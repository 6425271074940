import React from 'react';
import Helmet from 'react-helmet';
import spacetime from 'spacetime';

import { initGA, logPageView } from '../components/layout/google.js';
import { initFB, logFBPageView } from '../components/layout/facebook.js';
import BlockContent from '../components/core/block-content.js';
import ColumnLayout from '../components/core/column-layout.js';
import { Link } from 'gatsby';

class CaseStudyPage extends React.Component {
  componentDidMount() {
    initGA();
    logPageView();
    initFB();
    logFBPageView();
  }

  render() {
    const { data, title, slug, caseStudies, studyIndex } = this.props.pageContext;
    const {
      content,
      credits,
      featuredContent,
      featuredImage,
      metaCard,
      client,
      categories,
    } = data;

    let lastStudy, nextStudy;
    if (caseStudies && studyIndex !== undefined) {
      let lastIndex = studyIndex - 1;
      let nextIndex = studyIndex + 1;
      if (lastIndex < 0) {
        lastIndex = caseStudies.length - 1;
      }
      if (nextIndex > caseStudies.length - 1) {
        nextIndex = 0;
      }

      lastStudy = caseStudies[lastIndex];
      nextStudy = caseStudies[nextIndex];
    }

    return (
      <div>
        {metaCard ? (
          <Helmet title={`${title} - SpaceAgency`}>
            <meta
              name="og:url"
              content={`https://spaceagency.supercluster.com/case-study/${slug}`}
            />
            <meta httpEquiv="Accept-CH" content="DPR, Width, Viewport-Width" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:site_name" content={`${title} - SpaceAgency`} />
            <meta
              property="og:title"
              content={`${
                metaCard.fields.openGraphTitle
                  ? `${metaCard.fields.openGraphTitle}`
                  : `${title} - SpaceAgency`
              }`}
            />
            <meta
              property="og:description"
              content={`${
                metaCard.fields.openGraphDescription
                  ? `${metaCard.fields.openGraphDescription}`
                  : `${data.cardPreviewSnippet}`
              }`}
            />
            <meta
              property="og:image"
              content={`${
                metaCard.fields.openGraphShareImage
                  ? `${metaCard.fields.openGraphShareImage.fields.file.url}?fm=jpg&q=80&fl=progressive`
                  : `${featuredImage.fields.file.url}?fm=jpg&q=80&fl=progressive`
              }`}
            />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
              name="twitter:title"
              content={`${
                metaCard.fields.twitterCardTitle
                  ? `${metaCard.fields.twitterCardTitle}`
                  : `${title} - SpaceAgency`
              }`}
            />
            <meta
              name="twitter:image:src"
              content={`${
                metaCard.fields.twitterShareImage
                  ? `https:${metaCard.fields.twitterShareImage.fields.file.url}?fm=jpg&q=80&fl=progressive`
                  : `https:${featuredImage.fields.file.url}?fm=jpg&q=80&fl=progressive`
              }`}
            />
            <meta
              name="twitter:description"
              content={`${
                metaCard.fields.twitterCardDescription
                  ? `${metaCard.fields.twitterCardDescription}`
                  : `${data.cardPreviewSnippet}`
              }`}
            />
            <meta
              name="twitter:url"
              content={`https://spaceagency.supercluster.com/case-study/${slug}`}
            />
          </Helmet>
        ) : (
          <Helmet title={`${title} - SpaceAgency`}>
            <meta
              name="og:url"
              content={`https://spaceagency.supercluster.com/case-study/${slug}`}
            />
            <meta httpEquiv="Accept-CH" content="DPR, Width, Viewport-Width" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:site_name" content={`${title} - SpaceAgency`} />
            <meta property="og:description" content={`${data.cardPreviewSnippet}`} />
            <meta property="og:title" content={`${title} - SpaceAgency`} />
            <meta
              property="og:image"
              content={`${featuredImage.fields.file.url}?fm=jpg&q=80&fl=progressive`}
            />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={`${title} - SpaceAgency`} />
            <meta
              name="twitter:description"
              content={`${data.cardPreviewSnippet}`}
            />
            <meta
              name="twitter:image:src"
              content={`${featuredImage.fields.file.url}?fm=jpg&q=80&fl=progressive`}
            />
            <meta
              name="twitter:url"
              content={`https://spaceagency.supercluster.com/case-study/${slug}`}
            />
          </Helmet>
        )}
        <div>
          {featuredContent && (
            <div className="article__feature">
              {featuredContent.content.map(content => (
                <div key={Math.random()}>
                  {content.data.target && (
                    <img
                      className="block"
                      src={content.data.target.fields.file.url}
                      alt=""
                    />
                  )}
                  {content.content.length >= 1 &&
                    content.content.map(item => {
                      const contains = item.value.indexOf('soundcloud');
                      if (contains !== -1) {
                        return (
                          <div key={Math.random()}>
                            <div dangerouslySetInnerHTML={{ __html: item.value }} />
                          </div>
                        );
                      } else {
                        return (
                          <div key={Math.random()} className="article__video">
                            <div dangerouslySetInnerHTML={{ __html: item.value }} />
                          </div>
                        );
                      }
                    })}
                </div>
              ))}
            </div>
          )}
          <div className="article__header container--xl ma p15">
            <h2 className="sans caps m0 p0">{title}</h2>
            <div className="f fr fw x mb1 mt05">
              <div className="f1 s0 caps mt05" style={{ minWidth: '400px' }}>
                Client: {client}
              </div>
              {categories && (
                <div className="f1 s0 caps mt05" style={{ minWidth: '400px' }}>
                  {categories.join(', ')}
                </div>
              )}
            </div>
          </div>
          <div className="article__content">
            {content && content.content.map(item => BlockContent(item))}
          </div>
          <ColumnLayout className="container--xl ma p15">
            <div className="f1">
              <h6>CREDITS</h6>
              {credits &&
                credits.map((credit, index) => (
                  <div key={'credit' + index} className="par2 pb1 x mr15">
                    {credit}
                  </div>
                ))}
            </div>
            <div className="f1">
              <h6>CATEGORIES</h6>
              {categories && <div className="par2 p0">{categories.join(', ')}</div>}
            </div>
          </ColumnLayout>
          {lastStudy && nextStudy && (
            <div className="x px15 py2 mb2 f fr fw">
              <div className="f1 mt2 mr1" style={{ minWidth: '250px' }}>
                <Link
                  to={`/case-study/${lastStudy.slug}`}
                  className="h6 caps link__arrow f aic"
                >
                  <span className="arrow__left arrow ml05 block cw rel" />
                  {lastStudy.title}
                </Link>
              </div>
              <div className="f1 f jce mt2 ml1" style={{ minWidth: '250px' }}>
                <Link
                  to={`/case-study/${nextStudy.slug}`}
                  className="h6 caps link__arrow f aic ar"
                >
                  {nextStudy.title}
                  <span className="arrow__right arrow ml05 block cw rel" />
                </Link>
              </div>
            </div>
          )}
        </div>
        <div className="x bcw height-1" />
      </div>
    );
  }
}

export default CaseStudyPage;
